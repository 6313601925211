
/* eslint-disable */
export const PaxRegistrationStatus = {
    0: 'Registered',
    1: 'Waiting',
    2: 'Cancelled',
    3: 'Cancelled after deadline',
    4: 'Did not show up',
    5: 'Organizer',
    6: 'Application withdrawn',
};
