
export const Events = [
  {
    text: 'Academic Days',
    img: 'academicdays.png',
    description: `Academic Days events which are focused on specific IEM topics, aim to foster development of students and supports their academic lives. 
    During the events, students have a chance to gain deep knowledge with the lectures of high academic value given by professors. 
    While working hard during the daytime, students have time to test their knowledge and reflect the insights they gained with each other. 
    Besides, various leisure time activities in the events enhance the learning process of the students and make them have fun to the fullest.`,
    value: 11,
  },
  {
    text: 'Braintrainer',
    img: 'braintrainer.png',
    description: `BrainTrainer is a training-based service that provides high-quality experiences while aiming to develop certain soft skills to provide a self-growth adventure. 
    During BrainTrainer events, the learning by doing principle takes a major role, and participants are given the chance to work in an intercultural environment while aspiring 
    to become more professional and successful at the time being and in their future careers.`,
    value: 2,
  },
  {
    text: 'Business Booster',
    img: 'businessbooster.png',
    description: `businessbooster offers, events and courses on Business Innovation, giving participants the opportunity to learn all about entrepreneurship and intrapreneurship, promoting start-up thinking, 
    business disruption and supporting the idea generation within the network.`,
    value: 14,
  },
  {
    text: 'Europe3D',
    img: 'europe3d.png',
    description: `Europe3D gathers students from all around the world with different backgrounds to overview a country within 3 distinct dimensions - 
    Politics, Economy and Culture - while spreading open minded ESTIEM spirit and raising Intercultural Awareness. Europe3D events aim to give its participants a 
    better understanding of our cultural differences by experiencing together in a friendly atmosphere - while learning 3 dimensions of the hosting country by lectures, 
    trainings, debates, workshops, cultural activities, and more.`,
    value: 1,
  },
  {
    text: 'Lean Six Sigma',
    img: 'leansixsigma.png',
    description: `Lean Six Sigma is a methodology that relies on collaborative team effort to improve performance by systematically removing waste and reducing variation. 
    It combines Lean manufacturing/Lean enterprise and Six Sigma to eliminate the eight kinds of waste. The ESTIEM Lean Six Sigma Green Belt Course aims to provide ESTIEMers 
    an opportunity to obtain a high-quality Green Belt certificate already during their studies.`,
    value: 15,
  },
  {
    text: 'Summer Academy',
    img: 'summeracademy.png',
    description: 'Summer Academy is a service within the Personal Development Department that provides a chance to go on a retreat to discover one\'s inner motivations and opportunities for growth under the guidance of an Academic leader.',
    value: 3,
  },
  {
    text: 'TIMES',
    img: 'times.png',
    description: `TIMES (Tournament In Management and Engineering Skills) is the biggest Industrial Engineering and Management case study competition in Europe and the flagship project of ESTIEM.
    The competition has been successfully organized since 1994. Every year, more than 100 teams from all over Europe are being challenged in their management and 
    engineering skills and competing in three levels for the title of "IEM Student of the Year".`,
    value: 4,
    link: '/events/times',
  },
  {
    text: 'Vision',
    img: 'vision.png',
    description: `Vision is a bridge between academic achievements and the corporate environment. Since 1993, this service offers an opportunity for ESTIEMers to get
    together with the companies and experience the practical insights of theoretical knowledge into the areas of Technology, Economy, and Society. 
    Each Vision event aims to serve enlightenment on up-rising topics, broadening the network, and most importantly, creating a strong vision for the future!`,
    value: 5,
  },
  {
    text: 'a Local Group Exchange',
    img: 'blank.png',
    description: `Local Group Exchange is an event type that occurs by visits of LGs to other LGs with a subsequent return visit which facilitates them to share their cultures, 
    knowledge, experiences, and strengths with each other while having fun in a sincere environment.`,
    value: 9,
  },
  {
    text: 'an Activity Week',
    img: 'blank.png',
    description: 'Activity Week focuses on a specific cultural theme such as art, food, sport, history or leisure activities; offering ESTIEMers unique experiences while ensuring their intercultural development.',
    value: 10,
  },
  {
    text: 'a Council Meeting',
    img: 'blank.png',
    description: 'Organised twice a year and gathering 200 active students from all Europe, Council Meetings are the largest event in ESTIEM. ' +
      'They consist of the general assembly of the organisation and working groups, both aimed at sustaining the development of ESTIEM',
    value: 8,
  },
  {
    text: 'Central ESTIEM',
    img: 'blank.png',
    description: 'In Central ESTIEM events, the board, committees, and projects meet to advance the development of the network.',
    value: 7,
  },
  {
    text: 'the Language Programme',
    img: 'lpi.png',
    description: `Language Programme allows everyone to fully improve their language skills through connecting the learners with native speakers 
    and provides a wide variety of activities for all levels of language. It aims to support other events in language based activities and contribute to building cultural relations.`,
    value: 13,
  },
  {
    text: 'Be X For X Days',
    img: 'blank.png',
    description: `Be X for X Days aims to provide ESTIEMers and IEM students with the best opportunities of getting real-life experiences, 
    useful knowledge and insights about what is needed to be a good professional in a specific field by close contact with companies. 
    It is an event that gives participants the chance to experience the working life of an IEM-related profession. By close contact with companies, the aim 
    is to show the role of different professionals and open new career paths for ESTIEMers and IEM students.`,
    value: 23,
  },
];
